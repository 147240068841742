$(() => {
  $('#filters .collapsible-content .collapsible-content').each(function() {
    if ($(this).children().length > 10) {
      let maxHeight = $(this).find(".custom-checkbox").first().outerHeight(true) * 10; // max height is 10 times checkbox height
      $(this).height(maxHeight); // set max height to be 10x checkbox height (shows 10 first elements)
      $(this).parents(".form-group").siblings(".category-filter-display-more-wrap").show(); // show "display more button" so it's possible to reveal all the filter checboxes
    }
  });

  // When clicking the "display all button" add height 100% to the collapsible ajax_content
  // and hide the "display all button"
  $(".category-filter-display-more-wrap").on('click', function(e) {
    e.preventDefault();
    $(this).siblings('.form-group').find('.collapsible-content').first().height('100%');
    $(this).hide();
  });

  // Go through all the current nodes and open their parents
  $('.category-menu li.current').each(function() {
    $(this).parents('li').addClass('open');
  });
});
